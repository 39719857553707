import React from "react";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import bg from "./assets/DJI_0736.JPG";

const Home = () => {
  return (
    <>
      <Navbar />
      <div className="home-container w-full h-screen" id="dashboard">
        <div
          className="bg-cover bg-center w-full h-[85%]"
          style={{ backgroundImage: `url('${bg}')` }}
        ></div>
        <div className="mission bg-[#212529] flex justify-center">
          <div className="mission-wrapper w-[80%]">
            <p className="text-white text-3xl font-bold text-start pt-10">
              Visi & Misi
            </p>
            <p className="text-white text-2xl text-start">
              Visi Misi OSIS MTsN 1 Kota Kediri
            </p>
            <div className="mission-box w-full py-10 flex 2xl:flex-row xl:flex-row lg:flex-row flex-col justify-center">
              <div className="visi 2xl:w-1/2 xl:w-1/2 lg:w-1/2 w-full">
                <p className="text-white text-4xl mt-10 font-black">Visi</p>
                <p className="text-white 2xl:px-[60px] xl:px-[60px] lg:px-[60px] text-2xl mt-5">
                  Menjadikan OSIS MTsN 1 Kota Kediri menjadi satu organisasi
                  yang bermanfaat bagi lingkungan dan bisa menjadi contoh yang
                  baik bagi siswa-siswi madrasah lainnya
                </p>
              </div>
              <div className="misi 2xl:w-1/2 xl:w-1/2 lg:w-1/2 w-full">
                <p className="text-white text-4xl mt-10 font-black">Misi</p>
                <p className="text-white 2xl:px-[60px] xl:px-[60px] lg:px-[60px] text-2xl mt-5">
                  1. Memberikan yang terbaik dengan cara memberi contoh yang
                  baik pada siswa siswi Madrasah. agar selalu melibatkan Tuhan
                  YME dalam setiap urusannya <br /> 2. Mewujudkan proker yang
                  dapat memicu ke kreativitasan siswa-siswi Madrasah
                </p>
              </div>
            </div>
          </div>
        </div>

        <div
          className="lapinfaq flex justify-center py-5 bg-slate-600"
          id="lapinfaq"
        >
          <div className="lapinfaq-wrapper w-[80%] py-10">
            <p className="text-start text-white text-3xl font-semibold">
              Laporan Infaq
            </p>
            <p className="text-start text-2xl text-white">
              Laporan Infaq MTsN 1 Kota Kediri, Pada Hari Kamis dan hari Jum'at
            </p>
            <div className="lapinfaq-content w-full mt-4 flex 2xl:flex-row xl:flex-row lg:flex-row flex-col gap-10">
              <div className="lapinfaq-jumat 2xl:w-1/2 xl:w-1/2 lg:w-1/2 w-full bg-slate-200 rounded-lg py-5 shadow-sm shadow-slate-950">
                <p className="text-3xl font-black">Infaq Jumat (Siswa)</p>
                <p className="text-2xl mt-4">Rp. 30.000.0000</p>
              </div>
              <div className="lapinfaq-kamis 2xl:w-1/2 xl:w-1/2 lg:w-1/2 w-full bg-slate-200 rounded-lg py-5 shadow-sm shadow-slate-950">
                <p className="text-3xl font-black">
                  Infaq Kamis (Guru & Karyawan)
                </p>
                <p className="text-2xl mt-4">Rp. 30.000.0000</p>
              </div>
            </div>
          </div>
        </div>

        <div
          className="sugesstion flex justify-center bg-[#212529]"
          id="sugesstion"
        >
          <div className="sugesstion-wrapper w-[80%]">
            <p className="text-white text-3xl font-bold text-start pt-10">
              Kritik & Saran
            </p>
            <p className="text-white text-2xl text-start">
              Berikan Kritik & Saran Pada OSIS MTsN 1 Kota Kediri agar lebih
              baik dan lebih inovatif
            </p>
            <div className="p-12">
              <div className="w-ful">
                <form>
                  <div className="-mx-3 flex flex-wrap">
                    <div className="w-full px-3">
                      <div className="mb-5">
                        <input
                          type="text"
                          name="area"
                          id="area"
                          placeholder="Masukkan Nama Anda"
                          className="w-full rounded-md bg-slate-900 py-3 px-6 text-base font-medium text-white outline-none focus:border-[#6A64F1] focus:shadow-sm focus:shadow-slate-200"
                        />
                      </div>
                    </div>
                    <div className="w-full px-3">
                      <div className="mb-5">
                        <input
                          type="text"
                          name="city"
                          id="city"
                          placeholder="Masukkan Kelas / Instansi Anda"
                          className="w-full rounded-md bg-slate-900 py-3 px-6 text-base font-medium text-white outline-none focus:border-[#6A64F1] focus:shadow-sm focus:shadow-slate-200"
                        />
                      </div>
                    </div>
                    <div className="w-full px-3">
                      <div className="mb-5">
                        <input
                          type="text"
                          name="state"
                          id="state"
                          placeholder="Masukkan Saran Anda"
                          className="w-full rounded-md bg-slate-900 py-3 px-6 text-base font-medium text-white outline-none focus:border-[#6A64F1] focus:shadow-sm focus:shadow-slate-200"
                        />
                      </div>
                    </div>
                  </div>

                  <div className="flex justify-start">
                    <button className="hover:shadow-form w-[100px] rounded-md bg-[#6A64F1] py-3 px-8 text-base font-semibold text-white outline-none">
                      Kirim
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default Home;

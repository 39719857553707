import React, { useState } from "react";
import { Link } from "react-scroll";
import osisKemenag from "../assets/logo_osis_kemenag.png";
import { Squash as Hamburger } from "hamburger-react";
import { AiOutlineYoutube } from "react-icons/ai";
import { FaInstagram } from "react-icons/fa";
import { PiTiktokLogoLight } from "react-icons/pi";
import { SiInternetarchive } from "react-icons/si";

const Navbar = () => {
  const [isOpen, setOpen] = useState(false);
  return (
    <div className="navbar-container w-full h-[70px] 2xl:backdrop-blur-md xl:backdrop-blur-md lg:backdrop-blur-md  2xl:bg-transparent xl:bg-transparent lg:bg-transparent bg-slate-900 fixed flex justify-center">
      <div className="navbar-wrapper w-[90%] flex items-center">
        <div className="navbar-left 2xl:w-[45%] xl:w-[45%] lg:w-[45%] w-full">
          <div className="2xl:flex xl:flex lg:flex hidden justify-start items-center gap-3">
            <img src={osisKemenag} alt="" width={60} className="" />
            <p className="text-white text-2xl font-semibold">
              OSIS MTsN 1 Kota Kediri
            </p>
          </div>

          <div className="hamburger 2xl:hidden xl:hidden lg:hidden block justify-end">
            <Hamburger toggled={isOpen} toggle={setOpen} color="#ffff" />
          </div>
        </div>
        <div className="navbar-right w-[45%] 2xl:flex xl:flex lg:flex hidden justify-end gap-5">
          <Link
            to="dashboard"
            spy={true}
            smooth={true}
            offset={50}
            duration={500}
          >
            <button className="text-white font-semibold">Dashboard</button>
          </Link>
          <Link
            to="lapinfaq"
            spy={true}
            smooth={true}
            offset={50}
            duration={500}
          >
            <button className="text-white font-semibold">Laporan Infaq</button>
          </Link>
          <Link
            to="sugesstion"
            spy={true}
            smooth={true}
            offset={50}
            duration={500}
          >
            <button className="text-white font-semibold">Kritik Saran</button>
          </Link>
        </div>
      </div>
      {isOpen && (
        <div className="nav-wrapper absolute top-[70px] w-full h-screen  bg-black/40 backdrop-blur-md flex justify-center items-center">
          <div className="nav-brand flex flex-col text-start gap-5">
            <img src={osisKemenag} alt="" width={200} className="m-auto" />
            <p className="text-white text-2xl font-black text-center">
              OSIS MTsN 1 Kota Kediri
            </p>
            <Link
              to="dashboard"
              spy={true}
              smooth={true}
              offset={50}
              duration={500}
            >
              <p className="text-white font-semibold text-2xl p-2 rounded-sm bg-slate-600 transition-all duration-500 hover:shadow-md hover:shadow-slate-300 cursor-pointer">
                Dashboard
              </p>
            </Link>
            <Link
              to="lapinfaq"
              spy={true}
              smooth={true}
              offset={50}
              duration={500}
            >
              <p className="text-white font-semibold text-2xl p-2 rounded-sm bg-slate-600 transition-all duration-500 hover:shadow-md hover:shadow-slate-300 cursor-pointer">
                Laporan Infaq
              </p>
            </Link>
            <Link
              to="sugesstion"
              spy={true}
              smooth={true}
              offset={50}
              duration={500}
            >
              <p className="text-white font-semibold text-2xl p-2 rounded-sm bg-slate-600 transition-all duration-500 hover:shadow-md hover:shadow-slate-300 cursor-pointer">
                Kritik Saran
              </p>
            </Link>
            <div className="button flex justify-center gap-5 mt-4">
              <a href="#" target="__blank" rel="noreferrer">
                <p className="text-5xl text-white bg-slate-950 p-2 rounded-lg transition-all duration-500 hover:-translate-y-2">
                  <AiOutlineYoutube />
                </p>
              </a>
              <a href="#" target="__blank" rel="noreferrer">
                <p className="text-5xl text-white bg-slate-950 p-2 rounded-lg transition-all duration-500 hover:-translate-y-2">
                  <FaInstagram />
                </p>
              </a>
              <a href="#" target="__blank" rel="noreferrer">
                <p className="text-5xl text-white bg-slate-950 p-2 rounded-lg transition-all duration-500 hover:-translate-y-2">
                  <PiTiktokLogoLight />
                </p>
              </a>
              <a href="#" target="__blank" rel="noreferrer">
                <p className="text-5xl text-white bg-slate-950 p-2 rounded-lg transition-all duration-500 hover:-translate-y-2">
                  <SiInternetarchive />
                </p>
              </a>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Navbar;

import React from "react";
import osisKemenag from "../assets/logo_osis_kemenag.png";
import { MdEmail } from "react-icons/md";
import { AiOutlineYoutube } from "react-icons/ai";
import { FaInstagram } from "react-icons/fa";
import { PiTiktokLogoLight } from "react-icons/pi";
import { MdMapsHomeWork } from "react-icons/md";
import { SiGooglemaps } from "react-icons/si";

const Footer = () => {
  return (
    <div className="footer-container w-full flex justify-center py-10">
      <div className="footer-wrapper w-[80%] flex justify-center 2xl:flex-row xl:flex-row lg:flex-row flex-col gap-10">
        <div className="footer-left 2xl:w-[26%] xl:w-[26%] lg:w-[26%] w-full flex justify-center items-center gap-3">
          <img
            src={osisKemenag}
            alt=""
            width={60}
            className="2xl:block xl:block lg:block hidden"
          />
          <img
            src={osisKemenag}
            alt=""
            width={130}
            className="2xl:hidden xl:hidden lg:hidden block"
          />
          <p className="text-2xl font-semibold 2xl:block xl:block lg:block hidden">
            OSIS MTsN 1 Kota Kediri
          </p>
        </div>
        <div className="footer-center 2xl:w-[26%] xl:w-[26%] lg:w-[26%] w-full flex justify-center items-center flex-col">
          <p className="text-2xl text-start font-black">CONTACT</p>
          <div className="contact-list flex justify-start items-start flex-col mt-5 gap-3">
            <p className="text-2xl font-semibold">
              <span className="inline-flex gap-3 items-center">
                <MdEmail className="text-3xl" /> admin@osismasansa.com
              </span>
            </p>
            <p className="text-2xl font-semibold">
              <span className="inline-flex gap-3 items-center">
                <MdMapsHomeWork className="text-3xl" /> MTsN 1 Kota Kediri
              </span>
            </p>
            <p className="text-2xl font-semibold">
              <span className="inline-flex gap-3 items-center">
                <SiGooglemaps className="text-3xl" /> JL. Raung 87 Kota Kediri
              </span>
            </p>
          </div>
        </div>
        <div className="footer-right 2xl:w-[26%] xl:w-[26%] lg:w-[26%] w-full flex justify-center items-center flex-col">
          <p className="text-2xl font-black text-start">MEDIA SOSIAL</p>
          <div className="medsos-list flex justify-start items-start flex-col mt-5 gap-3">
            <p className="text-2xl font-semibold">
              <span className="inline-flex gap-3 items-center">
                <AiOutlineYoutube className="text-3xl" /> MASANSA Channel
              </span>
            </p>
            <p className="text-2xl font-semibold">
              <span className="inline-flex gap-3 items-center">
                <FaInstagram className="text-3xl" /> osismasansa
              </span>
            </p>
            <p className="text-2xl font-semibold">
              <span className="inline-flex gap-3 items-center">
                <PiTiktokLogoLight className="text-3xl" /> osismasansa
              </span>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
